import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/AppointmentForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AppointmentForm() {
  const [Name, setName] = useState("");
  const [Number, setNumber] = useState("");
  const [Gender, setGender] = useState("default");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [preferredMode, setPreferredMode] = useState("default");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    const errors = {};
    if (!Name.trim()) {
      errors.Name = "Name is required";
    } else if (Name.trim().length < 8) {
      errors.Name = "Name must be at least 8 characters";
    }

    if (!Number.trim()) {
      errors.Number = "Phone number is required";
    } else if (Number.trim().length !== 10) {
      errors.Number = "Phone number must be of 10 digits";
    }

    if (Gender === "default") {
      errors.Gender = "Please select gender";
    }
    if (!appointmentTime) {
      errors.appointmentTime = "Appointment time is required";
    } else {
      const selectedTime = new Date(appointmentTime).getTime();
      const currentTime = new Date().getTime();
      if (selectedTime <= currentTime) {
        errors.appointmentTime = "Please select a future appointment time";
      }
    }
    if (preferredMode === "default") {
      errors.preferredMode = "Please select preferred mode";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // If validation passes, send data to server
    try {
      const response = await fetch("/submit-appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Name,
          Number,
          Gender,
          appointmentTime,
          preferredMode,
        }),
      });

      if (response.ok) {
        toast.success("Appointment booked successfully!");
        // Reset form fields and errors after successful submission
        setName("");
        setNumber("");
        setGender("default");
        setAppointmentTime("");
        setPreferredMode("default");
        setFormErrors({});
      } else {
        toast.error("Failed to book appointment. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="appointment-form-section">
      <h1 className="legal-siteTitle">
        <Link to="/">Sedai BK Associates</Link>
      </h1>

      <div className="form-container">
        <h2 className="form-title">
          <span>Book Appointment Online</span>
        </h2>

        <form className="form-content" onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {formErrors.Name && (
              <p className="error-message">{formErrors.Name}</p>
            )}
          </label>

          <br />
          <label>
            Phone Number:
            <input
              type="text"
              value={Number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
            {formErrors.Number && (
              <p className="error-message">{formErrors.Number}</p>
            )}
          </label>

          <br />
          <label>
            Gender:
            <select
              value={Gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="default">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
              <option value="private">Prefer not to say</option>
            </select>
            {formErrors.Gender && (
              <p className="error-message">{formErrors.Gender}</p>
            )}
          </label>

          <br />
          <label>
            Preferred Appointment Time:
            <input
              type="datetime-local"
              value={appointmentTime}
              onChange={(e) => setAppointmentTime(e.target.value)}
              required
            />
            {formErrors.appointmentTime && (
              <p className="error-message">{formErrors.appointmentTime}</p>
            )}
          </label>

          <br />
          <label>
            Preferred Mode:
            <select
              value={preferredMode}
              onChange={(e) => setPreferredMode(e.target.value)}
              required
            >
              <option value="default">Select</option>
              <option value="voice">Voice Call</option>
              <option value="video">Video Call</option>
            </select>
            {formErrors.preferredMode && (
              <p className="error-message">{formErrors.preferredMode}</p>
            )}
          </label>

          <br />
          <button type="submit" className="text-appointment-btn">
            Confirm Appointment
          </button>

          <p
            className="success-message"
            style={{ display: isSubmitted ? "block" : "none" }}
          >
            Appointment details has been sent to the s phone number via SMS.
          </p>
        </form>
      </div>

      <div className="legal-footer">
        <p>© 2013-2024 Sedai BK Associates. All rights reserved.</p>
      </div>

      <ToastContainer autoClose={5000} limit={1} closeButton={false} />
    </div>
  );
}

export default AppointmentForm;
