import React from "react";
import AboutUs from "../Assets/AboutUs.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={AboutUs} alt="About Us" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          At Sedai BK Associates, we specialize in delivering comprehensive
          audit and assurance services tailored to meet the unique needs of our
          clients. With a commitment to integrity, accuracy, and excellence, our
          experienced team ensures your financial operations are transparent and
          compliant. Trust Sedai BK Associates to provide the insights and
          support you need to drive your business forward with confidence.
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Taxation Services"
          description="Managing tax complexity to maximize value is a key consideration of every major business decision."
        />

        <SolutionStep
          title="Risk Advisory Services"
          description="We help enhance your business performance by building strong risk management frameworks into the very core of your business processes."
        />

        <SolutionStep
          title="Other Advisory Services"
          description="Our huge international network gives us an edge in providing you with world class services at a local level."
        />
      </div>
    </div>
  );
}

export default About;
