import React, { useEffect, useState } from "react";
import Doctor from "../Assets/bksedai.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">Welcome to Sedai Bal Krishna Associates, 
            Registered Auditors</p>
          <h2 className="text-title">
          Focus your time and investment to run your business, we will provide accounting solutions.
          </h2>
          <p className="text-descritpion">
            Sedai Bal Krishna Associates is a registered accounting firm 
            to offer a wide range of financial services in the field of 
            auditing, accounting, taxation and management services for NPOs, 
            Schools, Cooperatives and SMEs at the Municipal, District, Province 
            and National level. Generally, we evaluate our client organization's 
            records, processes, and operations to ensure accuracy, compliance, 
            integrity and efficiency.  Our end result is to offer maintaining 
            organizational integrity, ensuring compliance, and driving continuous 
            improvement, and provide valuable insights and recommendations that enhance 
            overall performance and governance.
          </p>
          <button
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon={faCalendarCheck} /> Book Appointment
          </button>          
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
