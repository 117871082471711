export const customerReviews = [
    {
      "name": "Bishwasna Autolink Pvt. Ltd",
      "location": "Kathmandu, Nepal",
      "message": "Sedai BK Associates has thoroughly audited our company, and has provided insightful recommendations that have significantly improved our financial transparency and compliance. Highly recommend!"
    },
    {
      "name": "Laxmipur Suppliers",
      "location": "Kathmandu, Nepal",
      "message": "We have been working with Sedai BK Associates for the last two years, and their service has always been top-notch. Their dedication to excellence and client-focused solutions have greatly contributed to our success."
    },
    {
      "name": "Gautamland Construction Pvt. Ltd",
      "location": "Kathmandu, Nepal",
      "message": "Sedai BK Associates transformed our internal audit process. Their attention to detail and comprehensive approach improved our overall financial health. Their audits are thorough and their support team is incredibly responsive, making the entire process seamless."
    },
    {
      "name": "TM Traders",
      "location": "Kathmandu, Nepal",
      "message": "We appreciate the personalized service and deep industry knowledge that Sedai BK Associates brings to the table. Their proactive approach has helped us stay ahead in a competitive market."
    },
];
