import React, { useState, useEffect } from 'react';
import "../Styles/Hero.css";

function TextRotator({ textElements, interval = 2000 }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textElements.length);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [textElements.length, interval]);

  return (
    <div>
      <p>{textElements[currentIndex]}</p>
    </div>
  );
}

// Usage
const App = () => {
  const textList = [
    'Independence and Objectivity',
    'Professional Competence',
    'Confidentiality',
    'Ethics and Integrity',
    'Transparency',
    'Stakeholder Engagement'
    // Add more elements as needed
  ];

  return (
    <div className="text-stats">
      <div className="text-stats-container">
        {/* <h1>Text Rotator Example</h1> */}
        <p><TextRotator textElements={textList} /></p>
      </div>
    </div>
  );
};

export default App;